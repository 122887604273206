@import "../mixin";

.banner-register {
  width: 812px;
  position: fixed;
  left: calc(50vw - 380px);
  top: 20vh;
  box-shadow: var(--box-shadow-default);
  border-radius: 20px;
  background-size: cover;

  &__fields {
    position: relative;
    z-index: 1;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 12px;

    button {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.32px;
      padding: 16px;
    }
  }

  &__bg-coins {
    background-image: url("../../images/banner-register/bg-coins.png");
    background-size: cover;
    display: flex;
    overflow: hidden;
    position: relative;
    border-radius: 12px;
    color: var(--white);
    span {
      padding: 24px 0 24px 8px;
      color: var(--white);
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.48px;

      .banner-register__accent {
        color: var(--yellow-text);
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.48px;
      }
    }
    img {
      position: relative;
      right: 0;
      bottom: 0;
    }
  }

  &.variant1 {
    background: var(--bg-dark-gray) url("../../images/banner-register//bg-register-banners.png")
      no-repeat;
    background-size: cover;
    padding: 24px;

    .banner-register {
      &__img {
        position: relative;
        width: 528px;
        img {
          width: 560px;
          position: absolute;
          left: -120px;
          bottom: -65px;
        }
      }
      &__content {
        display: flex;
      }
    }
  }

  &.variant2 {
    background-color: var(--bg-dark-black-3);
    color: var(--white);

    .modal__close {
      right: 24px;
      top: 24px;
    }

    .banner-register {
      &__img {
        position: relative;
        left: 0;
        top: 0;

        h2 {
          position: absolute;
          line-height: 120%;
          font-size: 32px;

          img {
            width: 32px;
            height: 26px;
          }

          &.left {
            top: 24px;
            left: 24px;
            width: 120px;
          }
          &.right {
            text-align: right;
            right: -12px;
            top: 80px;
          }
        }
      }
      &__content {
        display: grid;
        grid-template-columns: 350px 1fr;
      }
      &__fields {
        padding: 24px;
      }
      &__form {
        margin-top: 24px;
      }
    }
  }

  &.variant-app {
    background-color: var(--bg-dark-black-3);
    color: var(--white);

    .modal__close {
      top: 24px;
      right: 24px;
    }

    .banner-register {
      &__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      &__img {
        background: url("../../images/banner-register/register-app-bg.png") no-repeat;
        background-size: cover;
        padding: 24px;

        h2 {
          position: relative;
          display: inline;

          .accent {
            font-weight: 700;
          }

          .custom-button__red {
            padding: 4px 8px;
            transform: rotate(21deg);
            border-radius: 16px;
            position: absolute;
            top: -12px;
            right: -24px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width: 18px;
              height: 18px;
              transform: rotate(-12deg);
            }
          }
        }
        p {
          color: var(--label-text-5);
          letter-spacing: -0.48px;
          max-width: 224px;
          font-size: 16px;
        }
        .accent {
          color: var(--red-pink);
        }
      }

      &__fields {
        padding: 24px;
      }
    }
  }

  @media (max-height: 695px) {
    position: relative;

    .modal__close {
      right: 2px;
      top: 2px;
    }

    &__form {
      gap: 8px;
    }

    &__bg-coins {
      & > span {
        padding: 16px 0 16px 4px;
        font-size: 16px;

        .banner-register__accent {
          font-size: 20px;
        }
      }

      img {
        width: auto;
        height: 80px;
      }
    }

    .custom-input {
      &__title {
        font-size: 14px;
      }

      input {
        padding: 12px 20px;
        font-size: 12px;
      }

      .password__hide__btn {
        display: flex;
        align-items: center;
        top: 0;
      }
    }

    &__img {
      img {
        width: 70vh;
        left: -6.6vw;
        bottom: -12.8%;
      }
    }
  }

  @media (max-height: 500px) {
    &__img {
      img {
        width: 80vh;
        left: 0vw;
        bottom: -11vh;
      }
    }
  }

  @include lsm {
    width: 80vw;
    position: fixed;
    left: 10vw;
    max-height: 95vh;
    top: 2vh;
    // max-height: 90vh;
    padding: 20px;
    padding-left: 30px;
    border-radius: 20px;
    background-image: url("../../images/banner-register/bg-register-banners-mob.png");
    overflow: hidden auto;
    padding-bottom: 0;

    &__content {
      flex-direction: column-reverse;
    }
    &__img {
      overflow: hidden;
      width: auto;
      img {
        top: 7vw;
        position: relative;
        width: 80vw;
        left: -2vw;
      }
    }
    &__bg-coins {
      & > span {
        font-size: 1.8rem;
        display: flex;
        align-items: center;

        .banner-register__accent {
          font-size: 1.8rem;
        }
      }
      img {
        margin-top: 3rem;
        width: 10rem;
        margin-left: auto;
      }
    }

    &.variant2 {
      .modal__close {
        right: 24px;
        top: 24px;
      }

      .banner-register {
        &__content {
          display: flex;
          flex-direction: column-reverse;
        }

        &__img {
          img {
            position: static;
          }
          h2 {
            &.left {
              top: 50px;
              left: 24px;
              width: 120px;
            }
            &.right {
              text-align: right;
              right: 24px;
              top: 112px;
            }
          }
        }
      }
    }

    &.variant-app {
      background-image: none;
      padding: 0;

      h1 {
        font-size: 26px;
        max-width: 90%;
      }

      h2 {
        font-size: 16px;
      }

      .close-modal {
        right: 12px;
        top: 12px;
      }
    }
  }

  @include sm {
    width: 95vw;
    position: fixed;
    left: 2.5vw;
    max-height: 90vh;
    top: calc(50vh - 250px);
    padding: 2rem;
    border-radius: 2rem;
    background-image: url("../../images/banner-register/bg-register-banners-mob.png");
    overflow: hidden auto;
    padding-bottom: 0;

    .text-error {
      font-size: 1.4rem;
    }

    .modal__close {
      right: 0.5rem;
      top: 0.5rem;
    }

    &__content {
      flex-direction: column-reverse;
    }

    &__img {
      overflow: hidden;
      img {
        top: 5rem;
        position: relative;
        width: 40rem;
        left: -2rem;
      }
    }
    &__bg-coins {
      span {
        font-size: 1.8rem;
      }
      img {
        margin-top: 3rem;
        width: 10rem;
      }
    }

    &.variant2 {
      background-image: "none";
      padding: 0;

      .modal__close {
        right: 2.4rem;
        top: 2.4rem;
      }

      .banner-register {
        &__img {
          display: none;

          img {
            width: 100%;
          }
          h2 {
            font-size: 2.8rem;

            &.left {
              top: 5rem;
              left: 2.4rem;
              width: 12rem;
            }
            &.right {
              right: 2.4rem;
              top: 11rem;
            }
          }
        }

        &__fields {
          padding: 2.4rem;
        }
        &__form {
          margin-top: 2.4rem;
        }
      }
    }

    &.variant-app {
      top: 2.5vh;
      max-height: 95vh;

      .banner-register {
        &__content {
          display: flex;
          flex-direction: column-reverse;
        }
        &__img {
          // aspect-ratio: 1;
          h2 {
            font-size: 1.8rem;
            .custom-button__red {
              right: -2.4rem;
              top: -1.2rem;
            }
          }
        }
      }
    }
  }

  @include b2k {
    width: 81.2rem;
    left: calc(50vw - 38rem);
    border-radius: 2rem;

    &__form {
      gap: 1.6rem;
      margin-top: 1.2rem;

      button {
        font-size: 1.6rem;
        padding: 1.6rem;
      }
    }

    &__bg-coins {
      border-radius: 1.2rem;
      span {
        padding: 2.4rem 0 2.4rem 0.8rem;
        font-size: 2.4rem;
        letter-spacing: -0.048rem;

        .banner-register__accent {
          font-size: 2.4rem;
        }
      }
    }

    &.variant1 {
      padding: 2.4rem;

      .banner-register {
        &__bg-coins {
          width: 36rem;

          .modal__close {
            width: 2rem;
            height: 2rem;
            top: 0.6rem;
            right: 0.6rem;
            svg {
              width: 1rem;
              height: 1rem;
            }
          }

          img {
            width: 16rem;
            margin-left: auto;
          }
        }

        &__img {
          width: 52.8rem;
          img {
            width: 56rem;
            left: -12rem;
            bottom: -6.5rem;
          }
        }
      }
    }

    &.variant2 {
      .modal__close {
        right: 2.4rem;
        top: 2.4rem;
        width: 2.8rem;
        height: 2.8rem;
        svg {
          width: 1.2rem;
          height: 1.2rem;
        }
      }

      .banner-register {
        &__img {
          h2 {
            font-size: 3.2rem;

            img {
              width: 3.2rem;
              height: 2.8rem;
            }

            &.left {
              top: 2.4rem;
              left: 2.4rem;
              width: 12rem;
            }
            &.right {
              right: -1.2rem;
              top: 8rem;
            }
          }

          img {
            width: 55rem;
          }
        }
        &__content {
          grid-template-columns: 35rem 1fr;
        }
        &__fields {
          padding: 2.4rem;

          h1 {
            font-size: 3.2rem;
          }
        }
        &__form {
          margin-top: 2.4rem;
          gap: 2.8rem;
        }
      }
    }

    &.variant-app {
      .modal__close {
        top: 2.4rem;
        right: 2.4rem;
        width: 2.8rem;
        height: 2.8rem;
        svg {
          width: 1.2rem;
          height: 1.2rem;
        }
      }

      .banner-register {
        &__img {
          padding: 2.4rem;

          h2 {
            font-size: 2.4rem;
            .accent {
              font-size: 2.4rem;
            }

            .custom-button__red {
              padding: 0.4rem 0.8rem;
              border-radius: 1.6rem;
              top: -1.2rem;
              right: -2.4rem;

              svg {
                width: 1.8rem;
                height: 1.8rem;
              }
            }
          }
          p {
            max-width: 22.4rem;
            font-size: 1.6rem;
          }
        }

        &__fields {
          padding: 2.4rem;
        }
      }
    }
  }
}

.russian {
  .banner-register__bg-coins span {
    font-size: 18px;
  }

  @include b2k {
    .banner-register__bg-coins span {
      font-size: 1.8rem;
    }
  }
}
