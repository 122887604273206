@mixin b4k {
  @media (min-width: 3840px) {
    @content;
  }
}
@mixin b2k {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin gm {
  @media (min-width: 1480px) {
    @content;
  }
}

@mixin gmm {
  @media (min-width: 1560px) {
    @content;
  }
}

@mixin gmmmax {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin lmm {
  @media (max-width: 1560px) {
    @content;
  }
}

@mixin lg {
  @media (max-width: 1480px) and (min-width: 740px) {
    @content;
  }
}

@mixin MobileHeader {
  @media ((max-width: 1150px)) {
    @content;
  }
}

@mixin l1220 {
  @media ((max-width: 1220px) and (min-width:950px)) {
    @content;
  }
}

@mixin lsm {
  @media (max-width: 950px) {
    @content;
  }
}
@mixin l670 {
  @media (max-width: 670px) {
    @content;
  }
}

@mixin lxs {
  @media (max-width: 576px) {
    &__content {
      padding-left: 12px;
      padding-right: 12px;
    }
    @content;
  }
}

@mixin llxs {
  @media (max-width: 428px) {
    @content;
  }
}

@mixin sm {
  @media (max-device-width: 670px) and (max-width: 670px) and (max-aspect-ratio: 13/9) {
    @content;
  }
}

@mixin smLsp {
  @media ((max-height: 670px) and  (orientation: landscape)) {
    @content;
  }
}

@mixin columnGap($gap: 12px) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin smOrB2k {
  @media (min-width: 1920px) or ((max-device-width: 670px) and (max-width: 670px) and (max-aspect-ratio: 13/9)) {
    @content;
  }
}
