@import "../mixin";

.premium-modal {
  border-radius: 20px;
  border-radius: 20px;
  box-shadow: var(--box-shadow-default);
  position: fixed;
  top: 15vh;
  width: 740px;
  left: calc(50% - 370px);
  color: var(--white);
  background: var(--bg-dark-black-3) var(--linear-02red);

  .modal__close {
    position: absolute;
    right: 24px;
    top: 24px;
  }

  &__content {
    display: grid;
    grid-template-columns: 260px 1fr;
  }

  &__banner {
    padding: 24px;
    background: url("../../images/common/bg-modal-pemium.png") no-repeat;
    background-size: cover;

    p {
      font-size: 12px;
      margin-top: 4px;
      width: 130px;
      img {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__info {
    padding: 16px 24px;

    h1 {
      font-size: 24px;
      line-height: 140%;
    }

    h4 {
      font-size: 18px;
      margin-top: 12px;
    }
    p {
      color: var(--label-text-5);
      margin-top: 4px;
      font-size: 14px;
    }
    ul {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      li {
        background: linear-gradient(180deg, var(--red) 0%, var(--orange) 100%);
        border-radius: 12px;
        overflow: hidden;
      }
      .check__list__span {
        background-color: var(--bg-dark-black-3);
        border-radius: 12px;
        margin: 1px;
        display: flex;
        font-size: 16px;
        font-weight: 600;
        gap: 8px;
        padding: 4px 8px;
        letter-spacing: -0.72px;
      }
    }

    .custom-button {
      margin-top: 16px;
      padding: 12px;
      width: 100%;
    }
  }

  @include lsm {
    width: 550px;
    left: calc(50% - 275px);

    .modal__close {
      right: 8px;
    }

    &__content {
      display: flex;
    }

    &__info {
      padding: 20px 12px;
    }

    &__banner {
      background-position-x: center;
    }

    .custom-button {
      svg {
        display: none;
      }
    }
  }

  @include lxs {
    width: 90vw;
    left: 5vw;
    top: 5vh;
    max-height: 90vh;
    overflow: auto;

    &__content {
      flex-direction: column-reverse;
      padding: 0;
    }

    &__banner {
      padding: 24px;
      background: url("../../images/common/bg-modal-premium-mobile.png") no-repeat;
      // aspect-ratio: 4/3;
      background-size: cover;
    }
  }

  @include sm {
    border-radius: 2rem;
    border-radius: 2rem;

    top: 5vh;
    max-height: 90vh;
    width: 95vw;
    left: 2.5vw;
    padding: 0.4rem;
    overflow: auto;

    .modal__close {
      right: 0.4rem;
      top: 1.2rem;
    }

    h2 {
      font-size: 2.2rem;
    }

    &__content {
      display: flex;
      flex-direction: column-reverse;
    }

    .crown-red {
      display: inline-flex;
      border-radius: 1.6rem;
      padding: 0.8rem 1.2rem;
      margin-top: 1.2rem;
    }

    &__banner {
      padding: 2rem;
      background: url("../../images/common/bg-modal-premium-mobile.png") no-repeat;
      background-size: cover;

      p {
        font-size: 1.2rem;
        margin-top: 0.4rem;
        width: 13rem;
        img {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }

    &__info {
      padding: 1.6rem 0.8rem;

      &__title {
        &__absolute {
          top: -2.5rem;
          right: -3.5rem;
          transform: rotate(30deg);

          svg {
            width: 1.6rem;
            height: 1.6rem;
          }
        }
      }

      h1 {
        font-size: 2.4rem;
        line-height: 140%;
      }

      h4 {
        font-size: 1.8rem;
        margin-top: 1.2rem;
      }
      p {
        margin-top: 0.4rem;
        font-size: 1.4rem;
      }
      ul {
        margin-top: 1.2rem;
        gap: 1rem;
        li {
          border-radius: 1.2rem;
        }
        .check__list__span {
          border-radius: 1.2rem;
          margin: 0.1rem;
          font-size: 1.6rem;
          gap: 0.8rem;
          padding: 0.4rem 0.8rem;
          letter-spacing: -0.072rem;
        }
      }

      .custom-button {
        margin-top: 1.6rem;
        padding: 1.2rem;
        font-size: 1.3rem;
      }
    }
  }
}
